import React, { useEffect, useState } from 'react';
import './style/searchWithAutocomplete.css';
import { TbViewfinder } from "react-icons/tb";
import { BiSearchAlt } from "react-icons/bi";
import { Tooltip } from 'react-tooltip'
import {
  Modal,
} from "reactstrap";
import { IoMdClose } from "react-icons/io";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import { useApi } from 'common/useApi';
import MoonLoader from "react-spinners/MoonLoader";
import toast, { Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';

function FullscreenInput({
  hunt, 
  handleInputChange, 
  handleSuggestionClick,
  handleReset,
  searchTerm, 
  suggestions,
  setSearchTerm,
  setSuggestions,
  suggestion,
  // setSuggestion,
  dataQuery,
  setTokensData,
  dispatch,
  tokensListLoading,
  setIsHunting,
  isHunting,
  isLoadingHunting
}) {
  const [showPopup, setShowPopup] = useState(false);
  const [loadingIndex, setLoadingIndex] = useState(null);
  const {getTokensByNameQuery} = useApi();


  // const handleOpenPopup = (e) => {
  //   e.preventDefault();
  //   setShowPopup(true);
  // };

  // const handleClosePopup = () => {
  //   setShowPopup(false);
  // };


  const toggle = (e) => {
    e.preventDefault()
    setShowPopup(!showPopup)
  };



  const handleClick = async (suggestion, e) => {
    try {
      const response  = await getTokensByNameQuery.mutateAsync({query: suggestion.contract_address});
      if(response.status === 'OK'){
        handleSuggestionClick(response.coins[0])
        response.coins[0].fromsearch = true
        dispatch(setTokensData(response.coins)); 
        setTimeout(()=>{
          toggle(e)
        },300)   
      }
    } catch (error) {
      console.error('Error:', error);
      return null; 
    }

  };

  useEffect(()=>{
    if(!isLoadingHunting){
      setShowPopup(false)
    }
  },[isLoadingHunting])


  return (
    <div className='mobile-search'>
      {/* <Toaster /> */}

      <button 
        onClick={toggle}
        className={`mobile-search-button ${suggestion !== '' || dataQuery !== '' ? 'on' : ''}`}
      >
        <BiSearchAlt size={17}/>
      </button>

      {(suggestion || dataQuery) && (
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <small className='sugestion-label'>
            {suggestion || dataQuery}
          </small>
          <button className='fast-close' onClick={e=>handleReset(e)}>
            <IoMdClose color='#fff' size={20}/>
          </button>
        </div>
      )}

      <Modal
        isOpen={showPopup}
        toggle={toggle}
        size="xl"
        fullscreen={true}
        className="transparent-modal"
        backdrop={true}
      >
        <div className="modal-body">
          <div className='modal-search-row'>
            <div style={{position:"relative", width: '100%'}}>
              <input 
                type="text"
                className="form-control"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleInputChange}
              />

              {tokensListLoading === false
              ? (searchTerm !== '' && dataQuery !== '' && !isHunting &&
                  <button 
                    className='reset-btn' 
                    onClick={e => {
                      setSearchTerm('')
                      setSuggestions([])
                      handleReset(e)
                    }}
                  >
                    <IoMdClose color='#fff' size={20}/>
                  </button>
                )
              : (
                  <div className='loader'>
                    <MoonLoader color="#ffffff" size={12}/>
                  </div>                
                )
              }
              
              <button 
                className='search-btn' 
                onClick={e=>{
                  if (dataQuery && dataQuery !== '' && isHunting) {
                    dispatch(setIsHunting(false))
                    handleReset(e)
                    setTimeout(()=>{
                      toggle(e)
                    },1000)    
                  }else{
                    dispatch(setIsHunting(true))
                    hunt(e)                 
                  }
   
                }}
                disabled={searchTerm.length <= 2 || suggestion !== ''}
                data-tooltip-id="search"
                data-tooltip-content={
                  dataQuery && dataQuery !== '' 
                  ? 'Hunting Activated' 
                  : 'Hunting Token'
                }
                style={
                  dataQuery && dataQuery !== '' && isHunting
                  ? {background: "#33d1d8", color: '#000'} 
                  : {background: "transparent", color: '#FFF'}
                }
              >
                {
                  dataQuery && dataQuery !== '' && isHunting 
                  ? isLoadingHunting ? <div className='btn-loader'><MoonLoader color="#000" size={12}/></div> : <IoMdClose color='#000' size={20}/> 
                  : <TbViewfinder color={dataQuery && dataQuery !== '' && isHunting ? '#000' : searchTerm.length <= 2 || suggestion !== '' ? '#323232' : '#9e9e9f'}/>
                }
              </button>

            </div>


          </div>
          <div className='modal-search-row'>
            {suggestions.length > 0 && (
              <ul className="suggestions-list">

              {suggestions.map((suggestion, index) => {
                return (
                  <li
                    key={index}
                    onClick={(e) => {
                      e.preventDefault();
                      handleClick(suggestion, e)
                      setLoadingIndex(index);
                    }}
                    className="suggestion-item"
                  >
                    <div className={getTokensByNameQuery.isLoading && loadingIndex === index ? 'wave-text' : ''}>
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '3px'}}>
                        {suggestion.contract_name} ({suggestion.contract_short_name}) 
                        <div style={{opacity: '.55', fontSize: '9px', fontWeight: '300', position: 'relative', top: '1px'}}>{suggestion?.ath_trx ? suggestion.ath_trx : '0'}</div>                      
                      </div>
                      <small style={{ opacity: '.25', fontSize: '10px', letterSpacing: '.1px' }}>
                        {suggestion.contract_address} 
                      </small>          
                    </div>
                  </li>
                )
              })}

              </ul>
            )}
          </div>

          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <IoMdClose color='#fff' size={40}/>
          </button>
        </div>
      </Modal>

      {window.innerWidth > 600 && <Tooltip id="search" />}
    </div>
  );
}

export default FullscreenInput;