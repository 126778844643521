import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';

import { connect } from "react-redux";

import { Link } from "react-router-dom";

// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import './style/tokenDetailsModal.css'
import './style/header.css'
//i18n
import { withTranslation } from "react-i18next";
import scanmeme from './../../pages/Authentication/img/scanmeme.svg'

import Ticker from "./Ticker";
import GaugeChart from 'react-gauge-chart'
import { Tooltip } from 'react-tooltip'
import Switch from "react-switch";
import { useDispatch } from 'react-redux';
import { setSwipeView, setScreenWidth } from './../../store/scanmeme/actions';
import { FaTableCellsLarge } from "react-icons/fa6";
import { MdOutlineViewColumn } from "react-icons/md";
import { MdOutlineViewDay } from "react-icons/md";
import SearchWithAutocomplete from './SearchWithAutocomplete'
import { useSelector } from 'react-redux';
import {
  Modal,
} from "reactstrap";

import TokenModalContent from "./TokenModalContent";
import MoonLoader from "react-spinners/MoonLoader";
import { useApi } from "common/useApi"; 

const Header = props => {
  // const [menu, setMenu] = useState(false);
  const {getTokensDashbordStatsQuery} = useApi();
  const [isSearch, setSearch] = useState(false);
  const authToken = sessionStorage.getItem('authToken');
  const [baseData, setBaseData] = useState(null)
  const [swipeView, set_swipeView] = useState(false);
  const [widthScreen, setWidthScreen] = useState(window.innerWidth);
  const [tokenModal, set_tokenModal] = useState(false);
  const dispatch = useDispatch();
  const tokensData = useSelector((state) => state.tokensData.tokensData);
  const [tokenDetail, set_tokenDetail] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [suggestion, setSuggestion] = useState('');
  const accessTier = sessionStorage.getItem('accessTier');


  const getTokensDashbordStats = async () => {
    const { data, error } = await getTokensDashbordStatsQuery.refetch();
    if (error) {
      console.error(error);
      return;
    }
    if (data.status === "OK") {
      setBaseData(data.stats)
    }
  };


  // const getDashData = async () => {
  //   const url = 'https://thingproxy.freeboard.io/fetch/https://api.scan.meme/api/dashboard/daily_stats';
  //   // const url = 'https://api.scan.meme/api/dashboard/daily_stats'
  //   let headers = {
  //     'Content-Type': 'application/json'
  //   };

  //   if (authToken) {
  //     headers['Authorization'] = authToken;
  //   }

  //   try {
  //     const response = await fetch(url, {
  //       method: 'GET',
  //       headers: headers
  //     });
    
  //     const data = await response.json();
  //     setBaseData(data.stats)  

  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // };

  const handleSwitchViewType = () => {
    set_swipeView(!swipeView);
    dispatch(setSwipeView(!swipeView));
  }

  const handleResize = () => {
    setWidthScreen(window.innerWidth);
    dispatch(setScreenWidth(window.innerWidth));
  };

  useEffect(() => {
    getTokensDashbordStats();
    let interval = setInterval(() => {
      getTokensDashbordStats();
    }, 600000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if(widthScreen > 600 && swipeView){
      handleSwitchViewType()
    }
    if(widthScreen < 600){
      set_swipeView(true);
      dispatch(setSwipeView(true));
    }
  }, [widthScreen]);

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  const chartStyle = {
    height: 50,
    width: 95,
    paddingTop: 5
  }

  const tog_TokenModal = (sugestion) => {
    set_tokenModal(!tokenModal);
    set_tokenDetail(sugestion)
    if(tokenModal === true){
      set_tokenDetail(null)
      setSearchTerm('')
      setSuggestions([])
      setSuggestion('')
    }
  };

  return (
    <React.Fragment>
      <header id="page-topbar" style={{background: "#000"}}>
        <div className="navbar-header">
          <div className="d-flex logotype">
            <div className="navbar-brand-box" style={{padding: '0 0 0 .9rem'}}>
              <Link to="/" className="logo logo-light">
                <span className="logo-lg">
                  <img src={scanmeme} alt="" height="40"/>
                </span>
              </Link>

              <Link to="/" className="logo logo-dark">
                <span className="logo-lg">
                  <img src={scanmeme} alt="" height="40" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
              data-toggle="collapse"
              onClick={() => {
                props.toggleLeftmenu(!props.leftMenu);
              }}
              data-target="#topnav-menu-content"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>
          <div className={`d-flex ${swipeView && widthScreen < 600 ? "mobile" : ""}`}>
            {getTokensDashbordStatsQuery.isLoading ? (
              <div className="loader-gauge-chart"><MoonLoader color="#fff" size={14}/></div>
            ) : baseData &&
              <span
                style={{minWidth: '95px'}}
                data-tooltip-id="fear_greed"
                data-tooltip-html={`<span style="text-align: center; display: block">FEAR/GREED index of SunPump platform<br/>based on volume, holders, tokens<br/> 
                  <b style="font-size: 15px">${(baseData.hour.fear_greed_index).toFixed(2)}%</b></span>`} // contract_address
              >
                  <GaugeChart 
                    id="gauge-chart1" 
                    style={chartStyle}
                    colors={['rgb(220, 31, 29)', 'rgb(216, 183, 68)', 'rgb(71, 154, 112)']}
                    animate={false} 
                    nrOfLevels={3} 
                    percent={baseData.hour.fear_greed_index / 100} 
                    needleColor="#fff"
                    hideText={true}
                  />
           
              </span>            
            }
             
              <SearchWithAutocomplete 
                data={tokensData}
                dispatch={dispatch}
                swipeView={swipeView}
                widthScreen={widthScreen}
                tog_TokenModal={tog_TokenModal}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                setSuggestions={setSuggestions}
                setSuggestion={setSuggestion}
                suggestions={suggestions}
                suggestion={suggestion}
              />
           
            {!swipeView && <Tooltip id="fear_greed" />}
          </div>
        

          <div className="slider">
            <div className="mask"></div>
            {getTokensDashbordStatsQuery.isLoading ? <div className="loader-topbar"><MoonLoader color="#fff" size={14}/>Loading...</div> : baseData && <Ticker data={baseData}/> }
          </div>

          <div className="d-flex user-menu">

            {/* <LanguageDropdown /> */}

            {widthScreen < 600 && (
              <div className="switch">
                <Switch 
                  onChange={handleSwitchViewType} 
                  checked={swipeView} 
                  height={24}
                  width={54}
                  handleDiameter={17}
                  onHandleColor="#000"
                  offHandleColor="#000"
                  onColor="#33d1d8"
                  offColor="#33d1d8"
                  uncheckedIcon={<MdOutlineViewColumn size={20} color="#000" style={{position: 'relative', top: '2px', left: '5px'}}/>}
                  checkedIcon={<MdOutlineViewDay size={20} color="#000" style={{position: 'relative', top: '2px', left: '8px'}}/>}
                />
              </div>
            )}

            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                className="btn header-item noti-icon "
                onClick={() => {
                  toggleFullscreen();
                }}
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen" style={{color: '#fff'}}/>
              </button>
            </div>
            <div className="profil-menu">
              <ProfileMenu
                swipeView={swipeView}
                widthScreen={widthScreen}
              />
            </div>

            <div className="dropdown d-inline-block">
              <button
                onClick={() => {
                  props.showRightSidebarAction(!props.showRightSidebar);
                }}
                type="button"
                className="btn header-item noti-icon right-bar-toggle "
              >
                <i className="bx bx-cog" style={{color: '#fff'}}/>
              </button>
            </div>
          </div>
        </div>
      </header>

      <Modal
        isOpen={tokenModal}
        toggle={() => {
          tog_TokenModal();
        }}
        size="xl"
        fullscreen={true}
        // style={{width: '100%', maxWidth: '80vw'}}
      >
        <TokenModalContent
          tokenDetail={tokenDetail}
          accessTier={accessTier}
        />

        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_TokenModal();
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
            style={{background: '#212227', border:0}}
          >
            Close
          </button>
        </div>
      </Modal>

    </React.Fragment>
  );
};

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header));
