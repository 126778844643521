import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import './style/searchWithAutocomplete.css';
import { setSuggestionData, setDataQuery, setTokensData, setIsHunting } from '../../store/scanmeme/actions';
import { TbViewfinder } from "react-icons/tb";
import { Tooltip } from 'react-tooltip';
import FullscreenInput from './FullscreenInput'; 
import sundog from './sundog.json';
import search from './search.json';
import { IoClose } from "react-icons/io5";
import { useApi } from 'common/useApi';
import MoonLoader from "react-spinners/MoonLoader";

const SearchWithAutocomplete = ({ 
  data, 
  dispatch,
  swipeView,
  widthScreen,
  tog_TokenModal,
  searchTerm,
  setSearchTerm,
  setSuggestions,
  setSuggestion,
  suggestions,
  suggestion,
}) => {
  const {getTokensListQuery, getTokensByNameQuery, getTokensByHuntQuery} = useApi();
  const authToken = sessionStorage.getItem('authToken');
  const debounceTimeout = useRef(null);
  const [query, setQuery] = useState('')
  const [loadingIndex, setLoadingIndex] = useState(null);
  const isHunting = useSelector((state) => state.isHunting.isHunting);
  const dataQuery = useSelector((state) => state.tokensData.dataQuery);
  const isLoadingHunting = useSelector((state) => state.tokensData.isLoadingHunting);
  const isSwipeView = useSelector((state) => state.tokensData.swipeView);

  const queryByName = async (tokenAddress) => {
    try {
      const response  = await getTokensByNameQuery.mutateAsync({query: tokenAddress});
      if(response.status === 'OK'){
        return response;
      }
    } catch (error) {
      console.error('Error:', error);
      return null; 
    }
  };

  const searchByName = async (query) => {
    try {
      const response  = await getTokensListQuery.mutateAsync({query: query});
      if(response.status === 'OK'){
        return response;
      }
    } catch (error) {
      console.error('Error:', error);
      return null; 
    }
  };

  const handleInputChange = (e) => {
    const userInput = e.target.value;
    setSearchTerm(userInput);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(async () => {
      if (userInput.length >= 3) {
        const dataQueryByName = await searchByName(userInput);
        // const dataQueryByName = search;

        if (dataQueryByName && dataQueryByName.coins.length > 0) {

          const dataQueryByNameCoins = dataQueryByName.coins || [];
          const dataQueryByNameCoinsWithDefaults = dataQueryByNameCoins.map(item => {
            console.log("item",item)
            if (item?.ath_trx === null || item?.ath_trx === undefined) {

              if (item) {
                item.ath_trx = 0;  
              } else {
     
                item = { ath_trx: 0 };
              }
            }
            return item;
          });
          
          const dataQueryByNameCoinsSorted = dataQueryByNameCoinsWithDefaults.sort((a, b) => {
            const athA = parseFloat(a?.ath_trx);
            const athB = parseFloat(b?.ath_trx);
          
            return athB - athA; 
          });

          const filteredSuggestions = dataQueryByNameCoinsSorted.filter(item => 
            item.contract_name.toLowerCase().includes(userInput.toLowerCase()) ||
            item.contract_short_name.toLowerCase().includes(userInput.toLowerCase()) ||
            item.contract_address.toLowerCase().includes(userInput.toLowerCase())
          );
          setSuggestions(filteredSuggestions);
          dispatch(setSuggestionData(filteredSuggestions));
        } else {
          setSuggestions([]);
          setSuggestion('');
        }
      } else {
        setSuggestions([]);
        setSuggestion('');
      }
    }, 800);
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchTerm(suggestion.token.contract_name);
    setSuggestion(suggestion.token.contract_name);
    setSuggestions([]);
    isSwipeView === true && dispatch(setDataQuery(suggestion.token.contract_name)); 
  };

  const hunt = (e) => {
    e.preventDefault();
    searchTerm && dispatch(setDataQuery(searchTerm));   
    setSuggestions([]);
  };

  const huntReset = (e) => {
    e.preventDefault();
    searchTerm && dispatch(setDataQuery('')); 
    setSearchTerm('');
  };

  const handleReset = () => {
    setSearchTerm('');
    setSuggestions([]);
    setSuggestion('');
    dispatch(setTokensData([])); 
  };

  const getTokenByQueryName = async (suggestion) => {
    const dataQueryByName = await queryByName(suggestion.contract_address)
    if(dataQueryByName){
      dataQueryByName.coins.length && tog_TokenModal(dataQueryByName.coins[0]);
      handleSuggestionClick(dataQueryByName.coins[0]);      
    }

  };

  useEffect(() => {
    if(searchTerm === ''){
      dispatch(setDataQuery(''));
    }
  }, [searchTerm]);

  const handleClick = (suggestion, index) => {
    setLoadingIndex(index); // Ustawiamy kliknięty element
    getTokenByQueryName(suggestion);
  };

  return (
    <form className="app-search">
      <Tooltip id="search" place={'top'} />
      {swipeView && widthScreen < 600 ? (
        <FullscreenInput
          hunt={hunt}
          handleInputChange={handleInputChange}
          handleSuggestionClick={handleSuggestionClick}
          setSearchTerm={setSearchTerm}
          setSuggestions={setSuggestions}
          setSuggestion={setSuggestion}
          handleReset={handleReset}
          searchTerm={searchTerm}
          suggestions={suggestions}
          suggestion={suggestion}
          swipeView={swipeView}
          widthScreen={widthScreen}
          dataQuery={dataQuery}
          setTokensData={setTokensData}
          dispatch={dispatch}
          tokensListLoading={getTokensListQuery.isLoading}
          setIsHunting={setIsHunting}
          isHunting={isHunting}
          isLoadingHunting={isLoadingHunting}
        />
      ) : (
        <>
          <div className="search-row">
            <input
              type="text"
              className="form-control"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleInputChange}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  hunt(e);
                }
              }}
            />

            {getTokensListQuery.isLoading 
              ? (
                <div className='loader'>
                  <MoonLoader color="#ffffff" size={12}/>
                </div>
              ) 
              : (
                <span className="bx bx-search-alt" />
              )
            }
            {searchTerm !== '' && dataQuery === '' &&
              <button
                className='search-btn' 
                onClick={(e) => {
                  e.preventDefault()
                  sessionStorage.setItem('dataQuery', '');
                  setSearchTerm('');
                  setSuggestions([]);
                  setSuggestion('');
                }}
              >
                <IoClose />
              </button>            
            }

            <button 
              className='search-btn' 
              disabled={searchTerm.length <= 2}
              onClick={(e) => {
                if (dataQuery && dataQuery !== '') {
                  dispatch(setIsHunting(false))
                  huntReset(e);
                } else {
                  dispatch(setIsHunting(true))
                  hunt(e);
                }
              }}
              data-tooltip-id="search"
              data-tooltip-content={
                dataQuery && dataQuery !== '' && isHunting
                ? `Hunting Activated - ${dataQuery}` 
                : 'Hunting Token'}
              style={
                dataQuery && dataQuery !== '' && isHunting
                ? { background: "#33d1d8", color: '#000' } 
                : {}}
            >
              {dataQuery && dataQuery !== '' && 
                isHunting 
                  ? isLoadingHunting && dataQuery !== ""
                    ? <div className='btn-loader'><MoonLoader color="#000" size={11}/></div> 
                    : <IoClose /> 
                  : <TbViewfinder />}
            </button>
          </div>  
          {suggestions.length > 0 && (
            <ul className="suggestions-list">
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onClick={() => handleClick(suggestion, index)}
                  className="suggestion-item"
                >
                  <div className={getTokensByNameQuery.isLoading && loadingIndex === index ? 'wave-text' : ''}>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '3px'}}>
                      {suggestion.contract_name} ({suggestion.contract_short_name}) 
                      <div style={{opacity: '.55', fontSize: '9px', fontWeight: '300', position: 'relative', top: '1px'}}>{suggestion?.ath_trx ? suggestion.ath_trx : '0'}</div>                      
                    </div>
                    <div>
                      <small style={{ opacity: '.25', fontSize: '10px', letterSpacing: '.1px' }}>
                        {suggestion.contract_address} 
                      </small>                       
                    </div>
                  </div>

                  {/* {getTokensByNameQuery.isLoading && loadingIndex === index && (
                    <div>
                      <MoonLoader color="#ffffff" size={12}/>
                    </div>
                  )} */}


                </li>
              ))}
            </ul>
          )}        
        </>
      )}
    </form>
  );
};

export default SearchWithAutocomplete;
