const initialState = {
  swipeView: '',
  screenWidth: '',
  tokensData: [],
  suggestionData: [],
  dataQuery: ''
};

const swipeViewReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_MY_VARIABLE':
      return {
        ...state,
        swipeView: action.payload,
      };

    case 'SET_SCREEN_WIDTH':
      return {
        ...state,
        screenWidth: action.payload,
      }; 

    case 'SET_TOKENS_DATA':
      return {
        ...state,
        tokensData: action.payload,
      };  

    case 'SET_SUGGESTION_DATA':
      return {
        ...state,
        suggestionData: action.payload,
      };  
    case 'SET_DATA_QUERY':
      return {
        ...state,
        dataQuery: action.payload,
      }; 

    case 'SET_IS_HUNTING':
      return {
        ...state,
        isHunting: action.payload,
      };  
    case 'SET_IS_LOADING_HUNTING':
      return {
        ...state,
        isLoadingHunting: action.payload,
      };  
    default:
      return state;
  }
};


export default swipeViewReducer;